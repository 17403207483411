<template>
  <div>


    <template v-if="storeProductData && storeProductFormData">
      <!-- GENERAL PRODUCT INFO -->
      <b-row class="match-height">
        <b-col cols="12" sm="12" md="12">
          <h3 class="mb-2">Información del producto</h3>
          <b-card>
            <store-product-info-card :product="storeProductData" />

            <!-- Variants -->
            <b-row
              v-if="
                storeProductData.store_product_variant_attributes &&
                storeProductData.store_product_variant_attributes.length > 0
              "
            >
              <b-col>
                <h5 class="mt-1 mr-1 mb-0">Variante:</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-badge
                  v-for="(variant, n) in storeProductData
                    .store_product_variant_attributes.variants"
                  :key="`variant-${n}`"
                  class="mr-1 mt-50 mb-1"
                  pill
                  variant="primary"
                  size="sm"
                >
                  {{ variant.variant_option_attributes.option_name }}
                </b-badge>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- STORE PRODUCT INFO -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-card>

            <b-row class="match-height">
              
              <!-- PRICE AND COST SECTION -->
              <b-col cols="12" sm="12" md="6">
                <b-card border-variant="primary">               
                  <!-- PRICE -->
                  <validation-provider
                    #default="{ errors }"
                    name="Precio"
                    :rules="{ required: true, price_less_than_cost: storeProductFormData.unit_cost }"
                  >
                    <b-form-group class="mt-2" label="Precio" label-for="precio">
                      <b-input-group>
                        <b-input-group-prepend is-text> $ </b-input-group-prepend>
                        <b-form-input
                          id="precio"
                          v-model="storeProductFormData.unit_price"
                          autofocus
                          :state="errors.length > 0 ? false : true"
                          trim
                          type="number"
                          step="0.01"
                          placeholder=""
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
  
                  <!-- COST -->
                  <validation-provider
                    #default="{ errors }"
                    name="Costo"
                    :rules="{ required: true, cost_less_than_price: storeProductFormData.unit_price }"
                  >
                    <b-form-group label="Costo" label-for="cost">
                      <b-input-group>
                        <b-input-group-prepend is-text> $ </b-input-group-prepend>
                        <b-form-input
                          id="cost"
                          v-model="storeProductFormData.unit_cost"
                          :state="errors.length > 0 ? false : true"
                          trim
                          type="number"
                          step="0.01"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
  
                  <!-- INVENTORY -->
                  <div
                    class="d-flex justify-content-between align-items-center my-2"
                  >
                    <label
                      for="check-button"
                      class="mb-0"
                    >
                      <b-col cols="12">
                        Inventario
                        <b-button
                          variant="link"
                          v-b-tooltip.hover
                          title="Si deseas gestionar tu inventario, podrás tener acceso a reportes de tu inventario como valor del inventario a precio de venta y valuación de tu inventario con el método estandar, PEPS (Primero en Entrar, Primero en Salir) y promedio. Considera que no podrás realizar ventas del producto si la cantidad en existencia es cero y tienes configurado que sí deseas gestionarlo."
                          class="p-0 ml-1 align-middle"
                        >
                          <i class="fas fa-question-circle" style="font-size: 15px; color: #25D366;"></i>
                        </b-button>
                      </b-col>
                    </label>
                    <b-button-group size="sm">
                      <b-button
                        :variant="
                          storeProductFormData.has_inventory
                            ? 'success'
                            : 'outline-secondary'
                        "
                        class="buttonWidth"
                        @click="storeProductFormData.has_inventory = true"
                      >
                        Gestionar
                      </b-button>
                      <b-button
                        :variant="
                          !storeProductFormData.has_inventory
                            ? 'danger'
                            : 'outline-secondary'
                        "
                        class="buttonWidth"
                        @click="storeProductFormData.has_inventory = false"
                      >
                        No
                      </b-button>
                    </b-button-group>
                  </div>
                  <div>
                    <b-row
                      v-if="storeProductFormData.has_inventory"
                      class="border-top pt-2 pb-2"
                    >
                      <b-col class="d-flex justify-content-start">
                        <div class="text-center">
                          <h6 class="text-muted font-weight-bolder">
                            Existencia
                          </h6>
                          <h3>
                            {{ formatInventory(storeProductFormData.inventory) }}
                          </h3>
                        </div>
                      </b-col>
                      <b-col sm="auto">
                        <div class="d-flex justify-content-end">
                          <b-button
                            v-if="!addProducts"
                            :disabled="removeProducts || isUnitsLoss"
                            size="sm"
                            class="mt-1"
                            variant="outline-primary"
                            @click="addProducts = true"
                          >
                            Agregar
                          </b-button>
                          <b-input-group v-else class="mt-1">
                            <b-form-input
                              v-model="unitsToAdd"
                              placeholder="Cantidad"
                              type="number"
                              size="sm"
                            />
                            <b-input-group-append>
                              <b-button
                                variant="outline-primary"
                                size="sm"
                                @click="addProducts = false"
                              >
                                <i class="fas fa-times" />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                        <div class="d-flex justify-content-end">
                          <b-button
                            v-if="!removeProducts"
                            :disabled="addProducts || isUnitsLoss"
                            size="sm"
                            class="mt-1"
                            variant="outline-primary"
                            @click="removeProducts = true"
                          >
                            Quitar
                          </b-button>
                          <b-input-group v-else class="mt-1">
                            <b-form-input
                              v-model="unitsToRemove"
                              placeholder="Cantidad"
                              type="number"
                              size="sm"
                            />
                            <b-input-group-append>
                              <b-button
                                variant="outline-primary"
                                size="sm"
                                @click="removeProducts = false"
                              >
                                <i class="fas fa-times" />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                        <div class="d-flex justify-content-end">
                          <b-button
                            v-if="!isUnitsLoss"
                            :disabled="addProducts || removeProducts"
                            size="sm"
                            class="mt-1"
                            variant="outline-primary"
                            @click="isUnitsLoss = true"
                          >
                            Perdidas
                          </b-button>
                          <b-input-group v-else class="mt-1">
                            <b-form-input
                              v-model="unitsLoss"
                              placeholder="Cantidad"
                              type="number"
                              size="sm"
                            />
                            <b-input-group-append>
                              <b-button
                                variant="outline-primary"
                                size="sm"
                                @click="isUnitsLoss = false"
                              >
                                <i class="fas fa-times" />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                      </b-col>
                    </b-row>
                  </div>

                  <!-- VALUACIÓN DE INVENTARIO -->
                  <b-table
                    v-if="storeProductFormData.has_inventory"
                    ref="inventoryValuationTable"
                    :items="inventoryValuation"
                    responsive
                    :fields="inventoryColumns"
                    primary-key="label"
                    show-empty
                    small
                    empty-text="No hay datos de inventario"
                    class="text-center inventory-table"
                  >
                    <!-- Column: Description -->
                    <template #cell(label)="data">
                      <strong>{{ data.item.label }}</strong>
                    </template>

                    <!-- Column: Unit cost -->
                    <template #cell(unit_cost)="data">
                      <span>{{ data.item.unit_cost }}</span>
                    </template>

                    <!-- Column: Total cost value -->
                    <template #cell(total_cost)="data">
                      <span>{{ data.item.total_cost }}</span>
                    </template>

                    
                  </b-table>

                  <div v-if="storeProductFormData.has_inventory" class="border-top pt-2 mt-2">
                    <p><strong>Inventario a precio de venta:</strong> {{ inventoryAtCurrentPrice }}</p>
                    <p><strong>Total de pérdidas registradas:</strong> {{ storeProductFormData.inventory_loss }}</p>
                  </div>
                </b-card>
              </b-col>
  
            
              <!--CONFIGURATION SECTION -->
              <b-col cols="12" sm="12" md="6">
                <b-card border-variant="primary">      
        
                  <!-- HAS TAX? -->
                  <div
                    class="d-flex justify-content-between align-items-center my-2"
                  >
                    <label
                      for="check-button"
                      class="mb-0"
                    >
                      <b-col cols="12">
                        ¿Sujeto a IVA?
                        <b-button
                          variant="link"
                          v-b-tooltip.hover.html="getTooltipHTML"
                          class="p-0 ml-1 align-middle"
                        >
                          <i class="fas fa-question-circle" style="font-size: 15px; color: #25D366;"></i>
                        </b-button>
                      </b-col>
                    </label>
                    <b-button-group size="sm">
                      <b-button
                        :variant="
                          storeProductFormData.has_tax
                            ? 'success'
                            : 'outline-secondary'
                        "
                        class="buttonWidth"
                        @click="storeProductFormData.has_tax = true"
                      >
                        Sí
                      </b-button>
                      <b-button
                        :variant="
                          !storeProductFormData.has_tax
                            ? 'danger'
                            : 'outline-secondary'
                        "
                        class="buttonWidth"
                        @click="setHasTax(false)"
                      >
                        No
                      </b-button>
                    </b-button-group>
                  </div>
        
                  <!-- PRICE INCLUDES TAX? -->
                  <div
                    class="d-flex justify-content-between align-items-center my-2"
                  >
                    <label
                      for="check-button"
                      class="mb-0"
                    >
                      <b-col cols="12">
                        ¿El precio incluye IVA ?
                        <b-button
                          variant="link"
                          v-b-tooltip.hover
                          title="¿El precio del prodcuto o servicio incluye IVA? Recuerda que debes incluir el IVA en el precio al público si facturas las ventas de productos."
                          class="p-0 ml-1 align-middle"
                        >
                          <i class="fas fa-question-circle" style="font-size: 15px; color: #25D366;"></i>
                        </b-button>
                      </b-col>
                    </label>
                    <b-button-group size="sm">
                      <b-button
                        :variant="
                          storeProductFormData.price_includes_tax
                            ? 'success'
                            : 'outline-secondary'
                        "
                        class="buttonWidth"
                        @click="setPriceIncludesTax(true)"
                      >
                        Sí
                      </b-button>
                      <b-button
                        :variant="
                          !storeProductFormData.price_includes_tax
                            ? 'danger'
                            : 'outline-secondary'
                        "
                        class="buttonWidth"
                        @click="storeProductFormData.price_includes_tax = false"
                      >
                        No
                      </b-button>
                    </b-button-group>
                  </div>
        
       
                  <!-- FAVORITE PRODUCT -->
                  <div
                    class="d-flex justify-content-between align-items-center my-2"
                  >
                    <label
                      for="check-button"
                      class="mb-0"
                    >
                      <b-col cols="12">
                        Favorito
                        <b-button
                          variant="link"
                          v-b-tooltip.hover
                          title="Si es favorito, el producto se agregará en la sección de favoritos en la Caja Registradora. De esta manera te será posible añadirlos al carrito sin necesidad de escanear o escribir ningún código o nombre de producto."
                          class="p-0 ml-1 align-middle"
                        >
                          <i class="fas fa-question-circle" style="font-size: 15px; color: #25D366;"></i>
                        </b-button>
                      </b-col>
                    </label>
                    <b-button-group size="sm">
                      <b-button
                        :variant="
                          storeProductFormData.is_favorite
                            ? 'success'
                            : 'outline-secondary'
                        "
                        class="buttonWidth"
                        @click="storeProductFormData.is_favorite = true"
                      >
                        Favorito
                      </b-button>
                      <b-button
                        :variant="
                          !storeProductFormData.is_favorite
                            ? 'danger'
                            : 'outline-secondary'
                        "
                        class="buttonWidth"
                        @click="storeProductFormData.is_favorite = false"
                      >
                        No
                      </b-button>
                    </b-button-group>
                  </div>
        
                  <!-- PRODUCT VISIBILITY -->
                  <div
                    class="d-flex justify-content-between align-items-center my-2"
                  >
                    <label
                      for="check-button"
                      class="mb-0"
                    >
                      <b-col cols="12">
                        Visibilidad
                        <b-button
                          variant="link"
                          v-b-tooltip.hover
                          title="Si es público, cualquier persona podrá ver el producto en tu tienda en línea. Si es privado, solo podrán verlo aquellos con invitación."
                          class="p-0 ml-1 align-middle"
                        >
                          <i class="fas fa-question-circle" style="font-size: 15px; color: #25D366;"></i>
                        </b-button>
                      </b-col>
                    </label>
                    <b-button-group size="sm">
                      <b-button
                        :variant="
                          !storeProductFormData.is_private
                            ? 'success'
                            : 'outline-secondary'
                        "
                        class="buttonWidth"
                        @click="storeProductFormData.is_private = false"
                      >
                        Publico
                      </b-button>
                      <b-button
                        :variant="
                          storeProductFormData.is_private
                            ? 'danger'
                            : 'outline-secondary'
                        "
                        class="buttonWidth"
                        @click="storeProductFormData.is_private = true"
                      >
                        Privado
                      </b-button>
                    </b-button-group>
                  </div>

                  <!-- UNIT TYPE -->
                  <b-row v-if="storeProductFormData.product_attributes.product_type=='product'">
                    <b-col sm="6">
                      <b-form-group
                        label="¿Cómo se vende?"
                        label-for="unit-type"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="UnitType"
                          rules="required"
                        >
                          <v-select
                            v-model="storeProductFormData.unit_code"
                            :options="unitCodeOptions"
                            :reduce="val => val.value"
                            label="name"
                            class="h-10"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
        
                  <!-- DESCRIPTION -->
                  <b-row>
                    <b-col>
                      <h5 class="mt-1 mr-1 mb-0">Descripción:</h5>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-textarea
                        v-model="storeProductFormData.description"
                        size="lg"
                      />
                    </b-col>
                  </b-row>
        
                </b-card> 
              </b-col>

              <!--ACTIONS SECTION -->
              <b-col cols="12" sm="12" md="12">
                <div class="d-flex mt-2">
                  <b-col cols="6">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      variant="success"
                      class="mr-0"
                      @click="isAddInventory()"
                    >
                      Modificar
                    </b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      block
                      variant="outline-warning"
                      @click="cancelEdit()"
                    >
                      Cancelar
                    </b-button>
                  </b-col>
                </div>
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="danger"
                    class="mr-0"
                    @click="changeActiveStatus()"
                  >
                    Eliminar producto de la tienda
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
  
      <b-row>
        <b-col cols="12">
          <promos-table-new />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import store from "@/store"
import router from "@/router"
import formValidation from "@core/comp-functions/forms/form-validation"
import { ValidationProvider, ValidationObserver, extend } from "vee-validate"
import { ref, watch, computed } from "@vue/composition-api"
import { mapActions } from "vuex"
import vSelect from 'vue-select'

import StoreProductInfoCard from "@/views/products/product-view/StoreProductInfoCard.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import PromosTableNew from "./PromosTableNew.vue"

import { required, alphaNum } from "@validations"

extend('cost_less_than_price', {
  params: ['price'],
  validate(value, { price }) {
    return Number(value) <= Number(price);
  },
  message: 'El costo no puede ser mayor que el precio unitario.'
});

extend('price_less_than_cost', {
  params: ['cost'],
  validate(value, { cost }) {
    return Number(value) >= Number(cost);
  },
  message: 'El costo no puede ser mayor que el precio unitario.'
});

export default {
  components: {
    // Local Components
    StoreProductInfoCard,
    PromosTableNew,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  setup(context) {
    //store.dispatch('promotions/fetchPromotions', {by_product: router.currentRoute.params.store_product_id, by_is_canceled: false})

    const storeProductData = ref(null)
    const storeProductFormData = ref(null)

    store
      .dispatch(
        "storeProducts/fetchStoreProduct",
        router.currentRoute.params.store_product_id
      )
      .then((response) => {
        storeProductData.value = response
        storeProductFormData.value = structuredClone(response)
      })

    const resetStoreProductData = () => {
      storeProductFormData.value = storeProductData.value
    }

    
    const { getValidationState } = formValidation(resetStoreProductData)

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      }).format(value);
    }

    const formatInventory = (value) => {
      if (value == null) return '0.00';
      return Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    const inventoryAtCurrentPrice = computed(() => {
      if (storeProductFormData.value && storeProductFormData.value.inventory && storeProductFormData.value.unit_price) {
        return formatCurrency(storeProductFormData.value.inventory * storeProductFormData.value.unit_price);
      } else {
        return 0;
      }
    });

    const inventoryValuation = computed(() => {
      if (storeProductFormData.value) {
        return [
          {
            label: "FIFO",
            unit_cost: formatCurrency(storeProductFormData.value.fifo_cost || 0),
            total_cost: formatCurrency(storeProductFormData.value.fifo_cost_value || 0),
          },
          {
            label: "Promedio",
            unit_cost: formatCurrency(storeProductFormData.value.average_cost || 0),
            total_cost: formatCurrency(storeProductFormData.value.average_cost_value || 0),
          },
          {
            label: "Actual",
            unit_cost: formatCurrency(storeProductFormData.value.unit_cost || 0),
            total_cost: formatCurrency(storeProductFormData.value.standard_cost_value || 0),
          },
        ];
      } else {
        return [];
      }
    });

    const isUnitCostValid = computed(() => {
      if (storeProductFormData.value) {
        return parseFloat(storeProductFormData.value.unit_cost) <= parseFloat(storeProductFormData.value.unit_price);
      }
      return true; // Si no hay datos, consideramos válido
    });

    function setNumberValues() {
      storeProductFormData.value.unit_price = Number(storeProductFormData.value.unit_price)
      storeProductFormData.value.unit_cost = Number(storeProductFormData.value.unit_cost)
      storeProductFormData.value.inventory = Number(storeProductFormData.value.inventory)
      storeProductFormData.value.inventory_loss = formatCurrency(Number(storeProductFormData.value.inventory_loss))
    }

    

    watch(storeProductData, (newVal) => {
      if (newVal) {
        storeProductFormData.value = structuredClone(newVal);
        setNumberValues()
      }
    });

    return {
      getValidationState,
      storeProductData,
      storeProductFormData,
      resetStoreProductData,
      inventoryValuation,
      inventoryAtCurrentPrice,
      isUnitCostValid,
      formatInventory,
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      addProducts: false,
      unitsToAdd: 1,
      removeProducts: false,
      unitsToRemove: 0,
      isUnitsLoss: false,
      unitsLoss: 0,
      isLoan: false,
      editMode: true,

      inventoryColumns: [
        {
          key: "label",
          label: "Descripción",
        },
        {
          key: "unit_cost",
          label: "Costo unitario",
        },
        {
          key: "total_cost",
          label: "Costo total",
        },
      ],


      unitCodeOptions: [
        {
          name: 'Por pieza',
          value: 'H87',
        },
        {
          name: 'Por kilogramo',
          value: 'KGM',
        },
        {
          name: 'Por litro',
          value: 'LTR',
        },
        {
          name: 'Por gramo',
          value: 'GRM',
        },
        {
          name: 'Por miligramo',
          value: 'MGM',
        },
        {
          name: 'Por mililitro',
          value: 'MLT',
        },
        {
          name: 'Por tonelada de carga',
          value: 'A75',
        },
      ],
    }
  },

  methods: {
    ...mapActions("storeProducts", ["editStoreProduct"]),
    ...mapActions("products", ["fetchProduct"]),
    ...mapActions("orders", ["dispatchOrder"]),
    
    getTooltipHTML() {
      const description = [
        'Los siguientes productos y servicios están exentos de IVA:',
      ];

      const products = [
        'Libros, revistas y periódicos.',
        'Venta de animales y vegetales no industrializados.',
        'Medicinas de patente.',
        'Joyería, oro, orfebrería, piezas ornamentales y artísticas.',
        'Invernaderos para cultivos.',
        'Alimentos procesados para mascotas.',
        'Chicles o gomas de mascar.',
        'Saborizantes, microencapsulados y aditivos alimentarios.',
        'Productos destinados a la alimentación, excepto por jugos y néctares.',
        'Venta de hielo y agua no gaseosa ni compuesta, en envases menores de 10 litros.',
        'Tractores para implementos agrícolas.',
        'Caviar, salmón ahumado y angulas.',
        'Fertilizante.',
        'Ixtle, lechuguilla y palma.',
        'Herbicidas, plaguicidas y fungicidas destinados a la agricultura o ganadería.'
      ];

      const services = [
        'Agricultura o ganadería.',
        'Pasteurización de leche.',
        'Molienda de granos (maíz, trigo, entre otros).',
        'Invernaderos hidropónicos.',
        'Despepite de algodón.',
        'Sacrificio de ganado y aves de corral.'
      ];

      // Generar el contenido HTML para el tooltip
      const descriptionList = `<p>${description.join('</p><p>')}</p>`;
      const productList = `<ul>${products.map(item => `<li>${item}</li>`).join('')}</ul>`;
      const serviceList = `<ul>${services.map(item => `<li>${item}</li>`).join('')}</ul>`;

      return `<strong>${descriptionList}</strong> <strong>Productos:</strong> ${productList} <br /><strong>Servicios:</strong> ${serviceList}`;
    },
    
    setHasTax(value) {
      this.storeProductFormData.has_tax = value
      this.storeProductFormData.price_includes_tax = value
    },

    setPriceIncludesTax(value) {
      this.storeProductFormData.price_includes_tax = value
      if (!this.storeProductFormData.has_tax && value) {
        this.storeProductFormData.price_includes_tax = false
        this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Inconsistencia!",
              icon: "CoffeeIcon",
              variant: "danger",
              text: "Has seleccionado que el precio incluye IVA, pero el producto no está sujeto a IVA. Por favor, modifica la configuración del producto sujeto a impuestos.",
            },
          })
      }
    },

    changeActiveStatus() {
      this.$swal({
        title: "¿Estás seguro?",
        text: "El producto será eliminado ¿Estás seguro que deseas continuar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, desactivar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.storeProductFormData.active_status = false
          this.onSubmit()
        }
      })
    },
    isAddInventory() {
      this.onSubmit()
    },
    completeOrderDependingType() {
      let type = null
      if (this.addProducts) type = "buy"
      if (this.removeProducts) type = "inventory_correction"
      if (this.isUnitsLoss) type = "inventory_loss"
      this.handleAddInventory(type)
    },
    handleAddInventory(type) {
      let handleUnits
      if (this.addProducts) handleUnits = this.unitsToAdd
      if (this.removeProducts) handleUnits = this.unitsToRemove
      if (this.isUnitsLoss) handleUnits = this.unitsLoss

      this.dispatchOrder({
        order: {
          order_type: type,
          payment_type: "cash",
          is_loan: this.isLoan,
          store_id: this.$route.params.store_id,
          order_store_products_attributes: [
            {
              store_product_id: this.$route.params.store_product_id,
              units: handleUnits,
            },
          ],
          order_payments_attributes: [
            {
              payment_type: "cash",
              amount: handleUnits * this.storeProductFormData.unit_cost,
            },
          ],
        },
        orderType: type,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Orden completada!",
              icon: "CoffeeIcon",
              variant: "success",
              text: "La orden ha sido completada exitosamente",
            },
          })
          this.$router.push({
            name: "store-products",
            params: { id: this.$route.params.store_id },
          })
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.product[0],
            },
          })
          this.$router.push({
            name: "store-products",
            params: { id: this.$route.params.store_id },
          })
        })
    },
    cancelEdit() {
      this.$router.push({
        name: "store-products",
        params: { id: this.$route.params.store_id },
      })
    },
    async onSubmit() {
      const isValid = await this.$refs.refFormObserver.validate();
      if (!isValid) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Error",
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Por favor, llena los campos requeridos correctamente",
          },
        })
        return
      };
      if (
        !this.addProducts &&
        !this.removeProducts &&
        !this.isUnitsLoss &&
        JSON.stringify(this.storeProductData) ===
          JSON.stringify(this.storeProductFormData)
      ) {
        this.$router.push({
          name: "store-products",
          params: { id: this.$route.params.store_id },
        })
      } else {
        this.storeProductFormData.tax_type = this.storeProductFormData.has_tax
          ? "IVA"
          : ""
        this.storeProductFormData.inventory = this.storeProductFormData.has_inventory
          ? this.storeProductFormData.inventory
          : 0 
        this.editStoreProduct(this.storeProductFormData)
          .then(() => {
            if (this.addProducts || this.removeProducts || this.isUnitsLoss) {
              this.completeOrderDependingType()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Información actualizada!",
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: "El producto ha sido actualizado exitosamente",
                },
              })
              this.$router.push({
                name: "store-products",
                params: { id: this.$route.params.store_id },
              })
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error",
                icon: "CoffeeIcon",
                variant: "danger",
                text: error.response.data.messages.product[0],
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.buttonWidth {
  width: 100px

}
</style>
